<script>
export default {
  name: "sbSetupSorgenteDati",
  async mounted() {},
  data() {
    return {
      sorgenteOptions: [
        {
          value: "datarete",
          text: "Data Rete",
          tabellaOptions: [
            {
              value: "pratiche",
              text: "Pratiche",
              campoOptions: [
                {
                  value: "stato",
                  text: "Stato",
                  valueOptions: [
                    {
                      value: "attivo",
                      text: "Attivo",
                    },
                    {
                      value: "non attivo",
                      text: "Non Attivo",
                    },
                    {
                      value: "in attesa",
                      text: "In attesa",
                    },
                  ],
                },
                {
                  value: "prodotto",
                  text: "Prodotto",
                  valueOptions: [
                    {
                      value: "mutui",
                      text: "Mutui",
                    },
                    {
                      value: "prestiti",
                      text: "Prestiti",
                    },
                    {
                      value: "cqs",
                      text: "CQS",
                    },
                    {
                      value: "corporate",
                      text: "Corporate",
                    },
                  ],
                },
                {
                  value: "istituto",
                  text: "Istituto",
                  valueOptions: [
                    {
                      value: "unicredit",
                      text: "Unicredit",
                    },
                    {
                      value: "sanpaolo",
                      text: "San Paolo",
                    },
                    {
                      value: "bpn",
                      text: "BPN",
                    },
                    {
                      value: "creditagricole",
                      text: "Credit Agricole",
                    },
                    {
                      value: "compass",
                      text: "Compass",
                    },
                  ],
                },
                {
                  value: "finalita",
                  text: "Finalita",
                  valueOptions: [
                    {
                      value: "nuovacasa",
                      text: "Nuova casa",
                    },
                    {
                      value: "secondacasa",
                      text: "Seconda casa",
                    },
                    {
                      value: "ristrutturazione",
                      text: "Ristrutturazione",
                    },
                    {
                      value: "altro",
                      text: "Altro",
                    },
                  ],
                },
                {
                  value: "importo",
                  text: "Importo",
                  valueOptions: null,
                },
                {
                  value: "professione",
                  text: "Professione",
                  valueOptions: [
                    {
                      value: "pubblico",
                      text: "Pubblico",
                    },
                    {
                      value: "privato",
                      text: "Privato",
                    },
                    {
                      value: "altro",
                      text: "Altro",
                    },
                  ],
                },
              ],
            },
            {
              value: "preventivi",
              text: "Preventivi",
            },
            {
              value: "anagrafiche",
              text: "Anagrafiche",
            },
            {
              value: "attivita",
              text: "Attivita",
            },
          ],
        },
        {
          value: "sqlserver",
          text: "Sql Server",
          tabellaOptions: [
            {
              value: "pratiche",
              text: "Pratiche",
            },
            {
              value: "preventivi",
              text: "Preventivi",
            },
            {
              value: "retail",
              text: "Retail",
            },
            {
              value: "optionsStati",
              text: "Options Stati",
            },
            {
              value: "driveFolders",
              text: "Drive Folders",
            },
            {
              value: "driveFiles",
              text: "Drive Files",
            },
            {
              value: "tipiProdotto",
              text: "Tipi Prodotto",
            },
            {
              value: "logs",
              text: "Logs",
            },
          ],
        },
        {
          value: "saleschat",
          text: "Sales Chat",
          tabellaOptions: [
            {
              value: "orders",
              text: "Orders",
            },
            {
              value: "products",
              text: "Products",
            },
            {
              value: "customers",
              text: "Customers",
            },
            {
              value: "messages",
              text: "Messages",
            },
          ],
        },
      ],
      tipoDatoOptions: ["Cumulativo", "Assoluto", "Percentuale"],
      frequenzaAggiornamentoOptions: [
        "Giornaliero",
        "Settimanale",
        "Mensile",
        "Anno",
      ],
      campi: [],
      sorgenteSelected: "",
      tabellaSelected: "",

      kpiName: "",
      tipoDatoSelected: "",
      frequenzaAggiornamentoSelected: "",
    };
  },
  computed: {
    currentSorgenteTabellaOptions() {
      const sorgente = this.sorgenteOptions.find(
        (s) => s.value === this.sorgenteSelected
      );
      return sorgente?.tabellaOptions || [];
    },
    currentCampoOptions() {
      const tabella = this.currentSorgenteTabellaOptions.find(
        (t) => t.value === this.tabellaSelected
      );
      return tabella?.campoOptions || [];
    },
  },
  methods: {
    handleAddCampo() {
      this.campi.push({
        text: "",
        value: null,
        valueOptions: null,
      });
    },
    handlesetCampoOptions(campo) {
      let completeCampo = this.currentCampoOptions.find(
        (c) => c.value === campo.text
      );

      console.log("completeCampo -> ", { campo, completeCampo });

      campo.valueOptions = completeCampo?.valueOptions || [];
    },
    handleDeleteCampo(index) {
      this.campi.splice(index, 1);
    },
  },
};
</script>

<template>
  <div class="px-4 py-4">
    <h1>Setup Sorgente di dati</h1>

    <b-container fluid>
      <b-row class="my-4">
        <b-col sm="12" md="6">
          <div class="d-flex flex-column">
            <span>Sorgente</span>
            <b-form-select
              v-model="sorgenteSelected"
              :options="sorgenteOptions"
            ></b-form-select>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="d-flex flex-column">
            <span>Tabella</span>
            <b-form-select
              v-model="tabellaSelected"
              :options="currentSorgenteTabellaOptions"
            ></b-form-select>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h4 class="mt-4">Applica filtri</h4>
        </b-col>

        <b-col cols="12" v-for="(campo, index) in campi" :key="index">
          <b-row class="my-2">
            <b-col sm="12" md="5">
              <div class="d-flex flex-column">
                <span>Campo</span>
                <b-form-select
                  v-model="campo.text"
                  @change="handlesetCampoOptions(campo)"
                  :options="currentCampoOptions"
                ></b-form-select>
              </div>
            </b-col>
            <b-col sm="12" md="5">
              <div class="d-flex flex-column">
                <span>Valore</span>
                <b-form-select
                  v-if="campo.valueOptions && campo.valueOptions.length > 0"
                  v-model="campo.value"
                  :options="campo.valueOptions"
                ></b-form-select>
                <b-form-input
                  v-else
                  type="text"
                  v-model="campo.value"
                ></b-form-input>
              </div>
            </b-col>
            <b-col sm="12" md="2">
              <div class="d-flex flex-column">
                <span>&nbsp;</span>
                <button
                  @click="handleDeleteCampo(index)"
                  class="btn btn-danger"
                >
                  X
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <div class="w-100 flex justify-content-end align-items-center py-4">
            <button @click="handleAddCampo" class="btn btn-secondary">
              + Aggiungi campo
            </button>
          </div>
        </b-col>
      </b-row>

      <b-row class="my-4">
        <b-col cols="12">
          <h4 class="mt-4">Definizione KPI</h4>
        </b-col>

        <b-col sm="12" md="6">
          <div class="d-flex flex-column my-2">
            <span>Nome KPI</span>
            <b-form-input type="text" v-model="kpiName"></b-form-input>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="d-flex flex-column my-2">
            <span>Tipo dato</span>
            <b-form-select
              v-model="tipoDatoSelected"
              :options="tipoDatoOptions"
            ></b-form-select>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="d-flex flex-column my-2">
            <span>Frequenza di aggiornamento</span>
            <b-form-select
              v-model="frequenzaAggiornamentoSelected"
              :options="frequenzaAggiornamentoOptions"
            ></b-form-select>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div
            class="w-100 flex justify-content-end align-items-center mt-4 py-4"
          >
            <button class="btn btn-primary">
              Salva
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
