<script>
export default {
  name: "sbSelezioneSorgente",
  async mounted() {
    await this.getOptions();
    await this.getWhappyPlugins();
  },
  data() {
    return {
      loadingOptions: false,
      loadingWhappyPlugins: false,
      options: [],
      whappyPlugins: [],
    };
  },
  methods: {
    async getOptions() {
      this.loadingOptions = true;
      this.options.splice(0);

      setTimeout(() => {
        this.options = [
          {
            name: "FTP",
            logo: "images/FTP.png",
          },
          {
            name: "Datarete",
            logo: "images/DATARETE.png",
          },
          {
            name: "SQL Server",
            logo: "images/SQLSERVER.png",
          },
          {
            name: "MySql",
            logo: "images/MYSQL.png",
          },
          {
            name: "File Excel",
            logo: "images/FILE.png",
          },
          {
            name: "SalesForce",
            logo: "images/SALESFORCE.png",
          },
        ];

        this.loadingOptions = false;
      }, 2000);
    },
    async getWhappyPlugins() {
      this.whappyPlugins.splice(0);

      this.loadingWhappyPlugins = true;

      setTimeout(() => {
        this.whappyPlugins = [
          {
            name: "detective",
            logo: "images/DETECTIVE.png",
          },
          {
            name: "Sales Chat",
            logo: "images/CHAT.png",
          },
          {
            name: "Accademy",
            logo: "images/ACCADEMY.png",
          },
          {
            name: "Shop",
            logo: "images/SHOP.png",
          },
        ];

        this.loadingWhappyPlugins = false;
      }, 2000);
    },
  },
};
</script>

<template>
  <div class="px-4 py-4">
    <h1>Aggiungi sorgente dati</h1>

    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <h4>Sorgenti esterne</h4>
        </b-col>
        <b-col cols="12">
          <b-row v-if="loadingOptions">
            <b-col cols="12">
              <div class="w-100 d-flex justify-content-center py-4">
                <b-spinner label="Spinning"></b-spinner>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              v-for="option in options"
              :key="option.name"
              sm="12"
              md="6"
              lg="4"
            >
              <div
                class="flex flex-column align-items-center white-box border-rounded-small min-h-100px p-4 mb-3 cursor-pointer hover-shadow"
              >
                <span class="font-bold">{{ option.name }}</span>
                <img :src="option.logo" style=" height: 50px" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <h4>Sorgenti interne</h4>
        </b-col>

        <b-col cols="12">
          <b-row v-if="loadingWhappyPlugins">
            <b-col cols="12">
              <div class="w-100 d-flex justify-content-center py-4">
                <b-spinner label="Spinning"></b-spinner>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              v-for="option in whappyPlugins"
              :key="option.name"
              sm="12"
              md="6"
              lg="4"
            >
              <div
                class="flex flex-column align-items-center white-box border-rounded-small min-h-100px p-4 mb-3 cursor-pointer hover-shadow"
              >
                <span class="font-bold">{{ option.name }}</span>
                <img :src="option.logo" style=" height: 50px" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="w-100 flex justify-content-end align-items-center mt-4">
            <button class="btn btn-primary">
              Aggiungi
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
